import { StaticImageData } from "next/image";
import { Routes } from "./routes";

import Bueroreinigung from "@images/Bueroreinigung.webp";
import Praxisreinigung from "@images/Praxisreinigung.webp";
import Treppenhausreinigung from "@images/Treppenhausreinigung.webp";
import Kindergarten from "@images/Kindergartenreinigung.webp";
import Fensterreinigung from "@images/Fensterreinigung.webp";
import Grundreinigung from "@images/Grundreinigung.webp";
import Solaranlagenreinigung from "@images/Solaranlagenreinigung.webp";
import Baureinigung from "@images/Baureinigung.webp";
import Gastronomiereinigung from "@images/Gastronomiereinigung.webp";

export interface serviceProps {
  imageSrc: StaticImageData;
  title: string;
  alt: string;
  text: string;
  route: string;
}

export function getServices() {
  const allServices: serviceProps[] = [
    {
      imageSrc: Grundreinigung,
      title: "Grundreinigung",
      alt: "Grundreinigung in Hamburg",
      text: "Die Grundreinigung ist in der Gebäudereinigung eine Leistung, die bei besonders starker Verschmutzung und als besonders gründliche Reinigung durchgeführt wird.",
      route: Routes.Grundreinigung,
    },
    {
      imageSrc: Bueroreinigung,
      title: "Büroreinigung",
      alt: "Büroreinigung in Hamburg",
      text: "Indem wir uns für saubere Arbeitsplätze einsetzen, wird die Zufriedenheit Ihrer Mitarbeiter durch das gesunde und attraktive Empfinden gesteigert.",
      route: Routes.Bueroreinigung,
    },
    {
      imageSrc: Praxisreinigung,
      title: "Praxisreinigung",
      alt: "Praxisreinigung in Hamburg",
      text: "Dadurch, dass wir uns um die Hygiene und Sauberkeit Ihrer Praxis kümmern, werden Ihre Patienten und Mitarbeiter geschützt sein und sich wohl fühlen.",
      route: Routes.Praxisreinigung,
    },
    {
      imageSrc: Kindergarten,
      title: "Kindergartenreinigung",
      alt: "Kindergartenreinigung in Hamburg",
      text: "Eine saubere Kita bietet für Kinder das perfekte Umfeld zum Wohlfühlen. Sie vermittelt Eltern und Besuchern einen perfekten Eindruck von guten hygienischen Verhältnissen.",
      route: Routes.Kindergartenreinigung,
    },
    {
      imageSrc: Treppenhausreinigung,
      title: "Treppenhausreinigung",
      alt: "Treppenhausreinigung in Hamburg",
      text: "Damit der erste visuelle Eindruck bei Kunden und Anwohnern Ihr Image verbessert, übernehmen wir die Reinigung für saubere Treppen und Flure.",
      route: Routes.Treppenhausreinigung,
    },
    {
      imageSrc: Fensterreinigung,
      title: "Fensterreinigung",
      alt: "Fensterreinigung in Hamburg",
      text: "Durch saubere Fenster steigern Sie das Wohlbefinden Ihrer Mitarbeiter und Kunden, sodass aus der Ferne schon ein gutes Gefühl entsteht.",
      route: Routes.Fensterreinigung,
    },
    {
      imageSrc: Solaranlagenreinigung,
      title: "Solaranlagenreinigung",
      alt: "Solaranlagenreinigung in Hamburg",
      text: "Die Solaranlagenreinigung ist ein wichtiger Prozess, um Schmutz, Staub und andere Ablagerungen von den Photovoltaikmodulen zu entfernen, um eine maximale Energieerzeugung sicherzustellen.",
      route: Routes.Solaranlagenreinigung,
    },
    {
      imageSrc: Baureinigung,
      title: "Baureinigung",
      alt: "Baureinigung in Hamburg",
      text: "Die Baureinigung ist von entscheidender Bedeutung, um Schmutz und Staub zu beseitigen und eine sichere Arbeitsumgebung auf der Baustelle zu gewährleisten.",
      route: Routes.Baureinigung,
    },
    {
      imageSrc: Gastronomiereinigung,
      title: "Gastronomiereinigung",
      alt: "Gastronomiereinigung in Hamburg",
      text: "Professionelle Gastronomiereinigungsdienste in Hamburg sorgen dafür, dass Gäste in Restaurants, Bars und Cafés eine angenehme und hygienische Umgebung vorfinden.",
      route: Routes.Gastronomiereinigung,
    },
  ];

  return allServices;
}
