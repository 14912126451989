import React, { useState } from "react";
import { getServices } from "../../helperFunctions/getServices";

import dynamic from "next/dynamic";
const Service = dynamic(() => import("./service"));

import styles from "./services.module.scss";

function Services() {
  const [allServices] = useState(getServices());

  const getServiceCollection = () => {
    return allServices.map((service, i) => (
      <React.Fragment key={i}>
        <Service
          imageSrc={service.imageSrc}
          title={service.title}
          alt={service.alt}
          text={service.text}
          route={service.route}
        />
      </React.Fragment>
    ));
  };

  return (
    <div
      className={`component-layout component-layout-border ${styles["services-container"]}`}
    >
      <div className={styles["services-header-container"]}>
        <p className={styles["services-title"]}>Unsere Dienstleistungen</p>
        <p className={styles["services-subtitle"]}>
          Wir sorgen dafür, dass sich Ihre Mitarbeiter, Kunden und
          Geschäftspartner in einem sauberen Umfeld wohl fühlen.
        </p>
      </div>
      <div className={styles["service-collection"]}>
        {getServiceCollection()}
      </div>
    </div>
  );
}

export default Services;
